<template>
<div class="languagePicker">
  <b-button-group vertical>
    <b-button
      v-for="language in availableLanguages"
      :key="language.id"
      :pressed="activeLanguage === language.id"
      :disabled="language.disabled"
      variant="outline-dark"
      size="sm"
      @click.prevent="setActiveLanguage(language.id)">
      {{ language.label }}
    </b-button>
  </b-button-group>
</div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
export default {
  name: 'SelectLanguage',
  computed: {
    ...mapGetters('front', [
      'getMatchingProducts'
    ]),
    ...mapGetters('front', [
      'languages'
    ]),
    ...mapState('front', [
      'activeCategory',
      'activeLanguage',
      'languages'
    ]),
    availableLanguages() {
      return this.languages
        .filter(language => language.id === 'all' || this.$store.getters['ping/parsedIdToken'].userData.languages.indexOf(language.id) !== -1)
        .map(language => {
          language.disabled = language.id !== 'all' && this.getMatchingProducts(this.activeCategory, language.id).length === 0;
          return language;
        });
    }
  },
  methods: {
    ...mapMutations('front', [
      'setActiveLanguage'
    ])
  },
}
</script>

<style lang="scss" scoped>
  .languagePicker {position: absolute; margin-left: -25px; margin-top: 25px;}
  @media only screen and (max-width:768px){
    .languagePicker { margin-left: -10px;}
  }
  .btn-outline-dark:not(:disabled):not(.disabled) { background-color: #f5f5f5;}
  .btn-outline-dark:not(:disabled):not(.disabled).active { background-color: #999999;}
  button:hover:not(.active) {color: #343a40;text-decoration: underline;}
</style>