import moment from 'moment';
import Cookies from 'js-cookie';

const recipientSchema = {
  customer_id: '',
  scanCode:'',
  shipping_address: {
    address1: '',
    address2: '',
    city: '',
    company_name: '',
    country_code: '',
    first_name: '',
    last_name: '',
    phone: '',
    postal_code: '',
    state_code: ''
  }
};

const defaultOrderProgressBar = {
  show: false,
  percent: 0,
  text: '',
  variant: 'info'
};

export default {
  namespaced: true,
  state: {
    loading: false,
    activeLanguage: 'all',
    activeCategory: process.env.VUE_APP_TOP_LEVEL_CATEGORY,
    defaultCategory: process.env.VUE_APP_TOP_LEVEL_CATEGORY,
    shippingMethod: process.env.VUE_APP_SHIPPING_METHOD,
    orderProgressBar: defaultOrderProgressBar,
    sentiments: [],
    languages: [
      {
        id: 'all',
        label: 'All Cards'
      },
      {
        id: 'en_US',
        label: 'English'
      },
      {
        id: 'es',
        label: 'Español'
      }
    ],
    customer: null,
    basket: null,
    order: null,
    product: null,
    categories: [],
    spanishCategories: [],
    products: [],
    libraryItems: [],
    blackoutDates: [],
    recipient: recipientSchema,
    defaultShipDate: '',
    orderHistory: [],
    tempOrderBatch: [],
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    setActiveCategory(state, payload) {
      state.activeCategory = payload;
    },
    setActiveLanguage(state, payload) {
      state.activeLanguage = payload;
    },
    setBasket(state, payload) {
      state.basket = payload;
    },
    setOrder(state, payload) {
      state.order = payload;
    },
    setProducts(state, payload) {
      state.products = payload;
    },
    setProduct(state, payload) {
      state.product = payload;
    },
    setCategories(state, payload) {
      state.categories = payload;
    },
    setSpanishCategories(state, payload) {
      state.spanishCategories = payload;
    },
    setLibraryItems(state, payload) {
      state.libraryItems = payload;
    },
    setBlackoutDates(state, payload) {
      state.blackoutDates = payload;
    },
    setRecipient(state, payload) {
      let generateRandomString = (length=10)=>Math.random().toString(20).substr(2, length);
      payload.scanCode = generateRandomString(10);
      state.recipient = payload;
    },
    setDefaultShipDate(state, payload) {
      state.defaultShipDate = payload;
    },
    setOrderHistory(state, payload) {
      state.orderHistory = payload;
    },
    setTempOrderBatch(state, payload) {
      state.tempOrderBatch = payload
    },
    updateOrderProgressBar(state, payload) {
      let newProgressBar = Object.assign({}, state.orderProgressBar);
      if ('show' in payload) {
        newProgressBar.show = payload.show;
      }
      if ('percent' in payload) {
        newProgressBar.percent = payload.percent;
      }
      if ('text' in payload) {
        newProgressBar.text = payload.text;
      }
      if ('variant' in payload) {
        newProgressBar.variant = payload.variant;
      }
      state.orderProgressBar = newProgressBar;
    },
    resetOrderProgressBar(state) {
      state.orderProgressBar = defaultOrderProgressBar;
    },
  },
  actions: {
    init({ commit, dispatch }) {
      commit('startLoading');
      return new Promise((resolve, reject) => {
        // set active language
        Promise.all([
          dispatch('fetchProducts'),
          dispatch('fetchCategories'),
          dispatch('fetchSpanishCategories'),
          dispatch('fetchBlackoutDates')
        ])
          .then(response => {
            commit('stopLoading');
            resolve(response);
          })
          .catch(error => {
            commit('stopLoading');
            reject(error);
          });
      });
    },
    reset({ commit }) {
      return new Promise((resolve) => {
        commit('setBasket', null);
        commit('setOrder', null);
        commit('setProduct', null);
        commit('setSentiments', []);
        resolve();
        // dispatch('deleteAllBaskets')
          // .then(resolve)
          // .catch(reject);
      });
    },
    fetchBlackoutDates({ commit, dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        dispatch('ocapi/fetchCustomObjects', {
          object_type: 'BlackoutDates',
          data: {
            query: {
              bool_query: {
                must: [
                  {
                    term_query: {
                      fields: ['site_id'],
                      operator: 'is',
                      values: [rootState.ocapi.siteId]
                    }
                  }
                ]
              }
            },
            sorts: [
              {
                field: 'lastModified',
                sort_order: 'desc'
              }
            ],
            count: 200,
            select: "(**)"
          }
        }, { root: true })
          .then(response => {
            commit('setBlackoutDates', response.data.hits)
            resolve(response);
          })
          .catch(reject);
      })
    },

    setActiveCategory({ commit }, payload) {
      commit('setActiveCategory', payload);
    },

    resolveCustomer({ rootState, commit, rootGetters, dispatch }) {
      return new Promise((resolve, reject) => {
        // check if ping user is existing customer in ocapi
        dispatch('fetchOcapiLoginForCurrentPingUser')
          .then(() => {
            // if they exist, log them in
            if (rootState.ocapi.login) {
              const inactiveAccountExpiryAfterDays = rootGetters.getClientConfig?.inactiveAccountExpiryAfterDays || 120;
              const allowedLastLogin = new Date();
              allowedLastLogin.setDate(allowedLastLogin.getDate() - inactiveAccountExpiryAfterDays);

              const error = {
                locked: true
              }
              const locked = rootGetters['ping/parsedIdToken'].locked;
              const checkLock = (process.env.VUE_APP_ENV === 'prod')
              if (checkLock && locked?.status) { // Already locked
                reject(error);
              } else if (checkLock && rootState.ocapi.lastLogin.getTime() < allowedLastLogin.getTime()) { // First time check
                dispatch('ping/lockUser', null, { root: true })
                reject(error);
              /* Ocapi last login updates after the first time check, so have commented below section it has more conditions to include
              } else if (!locked?.status && ((new Date(locked?.lockedOn)).getTime() > (new Date(locked?.unlockedOn)).getTime())) {
                dispatch('ping/lockUser', null, { root: true })
                reject(error);
              */
              } else {
                return Promise.resolve();
              }

            // otherwise
            // - create user in OCAPI
            // - log them in
            } else {
              return dispatch('ocapi/fetchGuestAuth', null, { root: true })
                .then(() => dispatch('ocapi/createCustomerExtProfile', null, { root: true }))
                .then(() => dispatch('ocapi/fetchCustomer', rootState.ocapi.customerExtProfile.customer_id, { root: true }))
                .then(() => {
                  commit('ocapi/setCurrentUserLogin', rootState.ocapi.customer.login, { root: true });
                  return Promise.resolve();
                })

                // Assigning to correct Population
                .then(() => {
                  if (rootGetters['ping/parsedIdToken'].idpid) {
                    return dispatch('ocapi/findCustomerGroupPopulation', rootGetters['ping/parsedIdToken'].idpid, { root: true })
                      .then(() => {
                        if (rootState.ocapi.populationId && rootGetters['ping/parsedIdToken'].populationID != rootState.ocapi.populationId) {
                          const userUpdates = {
                              user: rootGetters['ping/parsedIdToken'].preferred_username,
                              id: rootGetters['ping/parsedIdToken'].sub,
                              idpid: rootGetters['ping/parsedIdToken'].idpid
                          }
                          return dispatch('ping/resolveGroup', userUpdates, { root: true })
                            .then((response) => {
                              if (response.data && response.data.disableUser == 'true') {
                                const error = {
                                  disabled: true
                                }
                                reject(error);
                              } else {
                                return dispatch('ping/exchangeRefreshToken', { suppressResolveUserData: true }, { root: true })
                              }
                            })
                        }
                        return Promise.resolve();
                      })
                  }
                  return Promise.resolve();
                })
            }
          })
          // update OCAPI customer with latest Ping settings
          .then(() => dispatch('ocapi/updateCustomer', {
            c_pingPopulationID: rootGetters['ping/parsedIdToken'].populationID,
            first_name: rootGetters['ping/parsedIdToken'].given_name,
            last_name: rootGetters['ping/parsedIdToken'].family_name,
            email: rootGetters['ping/parsedIdToken'].email,
          }, { root: true }))
          .then(() => {
            if (rootGetters['ping/parsedIdToken']?.userData?.languages?.length === 1) {
              commit('setActiveLanguage', rootGetters['ping/parsedIdToken'].userData.languages[0]);
            }
            return dispatch('ocapi/fetchCustomerGroup', rootGetters['ping/parsedIdToken'].populationID, { root: true })
          })
          .then(() => {
            return dispatch('ping/resolveUserData', null, { root: true })
          })
          .then(() => {
            const client = Cookies.get('client', {
              cookieDomain: rootState.ping.cookieDomain
            });
            window.dataLayer?.push({
              event: 'log_in',
              email: rootGetters['ping/parsedIdToken']?.email,
              firstName: rootGetters['ping/parsedIdToken']?.given_name,
              lastName: rootGetters['ping/parsedIdToken']?.family_name,
              clientName: rootState.ocapi.customerGroup.description,
              clientSlug: (rootState.ping.config.policy && client?.length) ? client : null
            });
          })
          .then(() => {
            if (!rootState.ocapi.customerGroup) {
              reject();
            } else {
              // running this without waiting for result
              // as to not block access if error or no header banner slot configured
              dispatch('ocapi/fetchHeaderBannerSlotConfiguration', null, { root: true });
              resolve();
            }
          })
          .catch(reject);
      });
    },

    fetchOcapiLoginForCurrentPingUser({ dispatch, commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        dispatch('ocapi/searchCustomers', {
          query: {
            text_query: {
              fields: [
                'email'
              ],
              search_phrase: rootGetters['ping/parsedIdToken'].email
            }
          },
          select: '(**)'
        }, { root: true })
          .then(response => {
            if (response.data.count > 0) {
              const login = response.data.hits[0].data.credentials.login;
              const lastLogin = response.data.hits[0].data.last_login_time && response.data.hits[0].data.last_login_time !== 'Never' ? new Date(response.data.hits[0].data.last_login_time) : new Date();
              commit('ocapi/setCurrentUserLogin', login, { root: true });
              commit('ocapi/setLastLogin', lastLogin, { root: true });
              resolve(login);
            } else {
              resolve(null);
            }
          })
          .catch(reject);
      })
    },

    // products
    fetchProducts({ commit, dispatch, state }, payload) {
      const newPayload = {
        cgid: payload || state.defaultCategory
      }
      return new Promise((resolve, reject) => {
        dispatch('ocapi/fetchAllProducts', newPayload, { root: true })
          .then(products => {
            commit('setProducts', products);
            resolve(products);
          })
          .catch(reject);
      });
    },

    // basket
    createBasket({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('ocapi/createBasket', payload, { root: true })
          .then(response => {
            commit('setBasket', response.data)
            resolve(response);
          })
          .catch(reject);
      });
    },
    addCustomerInfoToBasket({ state, commit, dispatch, rootGetters }) {
      const payload = {
        basket_id: state.basket.basket_id,
        data: {
          // customer_id: rootGetters['ping/parsedIdToken'].customerID,
          email: rootGetters['ping/parsedIdToken'].email
        }
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/addCustomerInfoToBasket', payload, { root: true })
          .then(response => {
            commit('setBasket', response.data)
            resolve(response);
          })
          .catch(reject);
      });
    },
    setBasketPaymentInstrument({ commit, dispatch, state }) {
      const payload = {
        basket_id: state.basket.basket_id,
        data: {
          amount: state.basket.order_total,
          payment_method_id: 'AX_INVOICE'
        }
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/setBasketPaymentInstrument', payload, { root: true })
          .then(response => {
            commit('setBasket', response.data);
            resolve(response);
          })
          .catch(reject);
      });
    },
    setBasketBillingAddress({ commit, dispatch, state }, payload) {
      const newPayload = {
        basket_id: state.basket.basket_id,
        data: payload
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/setBasketBillingAddress', newPayload, { root: true })
          .then(response => {
            commit('setBasket', response.data);
            resolve(response);
          })
          .catch(reject);
      });
    },
    setShipmentShippingAddress({ commit, dispatch, state }, payload) {
      const newPayload = {
        basket_id: state.basket.basket_id,
        shipment_id: payload.shipment_id,
        data: payload.shipping_address
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/setShipmentShippingAddress', newPayload, { root: true })
        .then(response => {
          commit('setBasket', response.data);
          resolve(response);
        })
        .catch(reject);
      });
    },
    setShipmentShippingMethod({ commit, dispatch, state }, payload) {
      const newPayload = {
        basket_id: state.basket.basket_id,
        shipment_id: payload.shipment_id,
        data: payload.shipping_method
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/setShipmentShippingMethod', newPayload, { root: true })
          .then(response => {
            commit('setBasket', response.data);
            resolve(response);
          })
          .catch(reject);
      });
    },
    updateShipment({ commit, dispatch, state }, payload) {
      const newPayload = {
        basket_id: state.basket.basket_id,
        shipment_id: payload.shipment_id,
        data: payload.data
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/updateShipment', newPayload, { root: true })
          .then(response => {
            commit('setBasket', response.data);
            resolve(response);
          })
          .catch(reject);
      });
    },
    addItemToBasket({ commit, dispatch, state }, payload) {
      const newPayload = {
        basket_id: state.basket.basket_id,
        item: payload
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/addItemToBasket', newPayload, { root: true })
          .then(response => {
            commit('setBasket', response.data);
            resolve(response);
          })
          .catch(reject);
      });
    },
    updateItemInBasket({ state, commit, dispatch }, payload) {
      const newPayload = {
        basket_id: state.basket.basket_id,
        item_id: payload.item_id,
        data: payload.data
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/updateItemInBasket', newPayload, { root: true })
          .then(response => {
            commit('setBasket', response.data);
            resolve(response);
          })
          .catch(reject);
      })
    },
    deleteAllBaskets({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('ocapi/fetchCustomerBaskets', null, { root: true })
          .then(baskets => {
            if (baskets.length) {
              const deleteRequests = baskets.map(basket => dispatch('ocapi/deleteBasket', basket.basket_id, { root: true }));
              return Promise.all(deleteRequests);
            }
            return Promise.resolve();
          })
          .then(resolve)
          .catch(reject);
      });
    },
    createOrder({ commit, dispatch, state }) {
      const payload = {
        basket_id: state.basket.basket_id
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/createOrder', payload, { root: true })
          .then(response => {
            commit('setOrder', response.data);
            resolve(response);
          })
          .catch(reject);
      });
    },
    patchOrder({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('ocapi/patchOrder', payload, { root: true })
          .then(response => {
            commit('setOrder', response.data);
            resolve(response);
          })
          .catch(reject);
      });
    },
    updateOrderStatus({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('ocapi/updateOrderStatus', {
          order_no: state.order.order_no,
          status_name: 'confirmation_status',
          data: { status: 'confirmed' }
        }, { root: true })
        .then(() => dispatch('ocapi/updateOrderStatus', {
          order_no: state.order.order_no,
          status_name: 'status',
          data: { status: 'new' }
        }, { root: true }))
        .then(() => dispatch('ocapi/updateOrderStatus', {
          order_no: state.order.order_no,
          status_name: 'export_status',
          data: { status: 'ready' }
        }, { root: true }))
        .then(resolve)
        .catch(reject);
      });
    },

    // categories
    fetchCategories({ commit, dispatch, state }, payload) {
      const newPayload = {
        category_id: payload || state.defaultCategory
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/fetchCategories', newPayload, { root: true })
          .then(response => {
            commit('setCategories', response.data.categories);
            resolve(response);
          })
          .catch(reject);
      });
    },

    // spanish categories
    fetchSpanishCategories({ commit, dispatch, state }, payload) {
      const newPayload = {
        category_id: payload || state.defaultCategory,
        params: {
          locale: 'es'
        }
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/fetchCategories', newPayload, { root: true })
          .then(response => {
            commit('setSpanishCategories', response.data.categories);
            resolve(response);
          })
          .catch(reject);
      });
    },

    // products
    fetchProduct({ commit, dispatch }, payload) {
      const newPayload = {
        product_id: payload
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/fetchProduct', newPayload, { root: true })
          .then(response => {
            commit('setProduct', response.data);
            resolve(response);
          })
          .catch(reject);
      });
    },

    fetchLibraryItems({ commit, dispatch }, payload) {
      const newPayload = {
        folder_id: payload
      };
      return new Promise((resolve, reject) => {
        dispatch('ocapi/fetchLibraryFolder', newPayload, { root: true })
          .then(response => {
            commit('setLibraryItems', response.data?.hits || []);
            resolve(response);
          })
          .catch(reject);
      });
    },

    fetchProductInfo({ state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('fetchProduct', payload)
          .then(() => dispatch('fetchLibraryItems', state.product.c_hbc_SentimentCategory))
          .then(resolve)
          .catch(reject);
      });
    },

    // order
    placeOrder({ dispatch, state, rootState, getters, commit }, payload) {
      const item_defaults = {
        product_id: '1HBE2115',
        quantity: 1,
      };
      const shipment_id = 'me';
      const item = Object.assign({}, item_defaults, payload.item);      
      return new Promise((resolve, reject) => {
        // initiate and store personalization request
        commit('updateOrderProgressBar', {
          show: true,
          text: 'Personalizing card...',
          percent: 10
        })
        const envelopeRequest = dispatch('personalization/renderEnvelope', {returnAddress: payload.returnAddress, recipientAddress: payload.recipientAddress, scanCode: payload.item.c_scanCode}, { root: true });
        const personalizationRequest = dispatch('personalization/personalizeCard', payload.personalization, { root: true });
        const createBasket = dispatch('createBasket');
        createBasket
          .then(() => {
            commit('updateOrderProgressBar', { percent: 20, text: 'Saving information...' });
            const addCustomerInfoToBasket = dispatch('addCustomerInfoToBasket');
            return addCustomerInfoToBasket;
          })
          .then(() => {
            commit('updateOrderProgressBar', { percent: 30, text: 'Saving card to order...' });
            const addItemToBasket = dispatch('addItemToBasket', item);
            return addItemToBasket;
          })
          .then(() => {
            commit('updateOrderProgressBar', { percent: 40, text: 'Saving shipping info...' });
            const updateShipment = dispatch('updateShipment', {
              shipment_id,
              data: {
                shipping_address: payload.order.shipping_address,
                shipping_method: {
                  id: state.shippingMethod
                },
                c_req_ship_date: payload.order.c_req_ship_date,
                c_taxClassCode: payload.order.c_taxClassCode
              }
            });
            return updateShipment;
          })
          .then(() => {
            commit('updateOrderProgressBar', { percent: 50, text: 'Saving billing info...' });
            const setBasketBillingAddress = dispatch('setBasketBillingAddress', payload.order.billing_address);
            return setBasketBillingAddress;
          })
          .then(() => {
            const setBasketPaymentInstrument = dispatch('setBasketPaymentInstrument');
            return setBasketPaymentInstrument;
          })
          // update ProductLineItem with Envelope PDF url when ep completes
          .then(() => {
            commit('updateOrderProgressBar', { percent: 60, text: 'Finalizing Order...' });
            return envelopeRequest;
          })
          .then(response => {
            const updateItemInBasket = dispatch('updateItemInBasket', {
              item_id: state.basket.product_items[0].item_id,
              data: {
                quantity: 1,
                c_hbc_PrintPDF_EP: response.data.pdfDetails.imageUrl
              }
            });
            return updateItemInBasket;
          })
          // update ProductLineItem with PrintPdf url when personalization complete
          .then(() => {
            commit('updateOrderProgressBar', { percent: 70, text: 'Finalizing Order...' });
            return personalizationRequest;
          })
          .then(response => {
            const updateItemInBasket = dispatch('updateItemInBasket', {
              item_id: state.basket.product_items[0].item_id,
              data: {
                quantity: 1,
                c_hbc_PrintPdf: response.data.pdfDetails.imageUrl
              }
            });
            return updateItemInBasket;
          })
          .then(() => {
            commit('updateOrderProgressBar', { percent: 90, text: 'Placing order...' });
            const createOrder = dispatch('createOrder');
            return createOrder;
          })
          .then(() => {
            const patchOrder = dispatch('patchOrder', {
              order_no: state.order.order_no,
              data: {
                c_clientID: rootState.ocapi.customerGroup.id,
                c_clientName: rootState.ocapi.customerGroup.description,
                c_senderProfile: payload.order.sender_profile.profileName,
                c_senderProfileID: payload.order.sender_profile.profileID,
                c_ax_customer_no: rootState.ocapi.customerGroup.c_axCustomerAccountID,
                c_ax_invoice_id: payload.order.sender_profile.axInvoiceAccount,
                c_ax_custom_fields: JSON.stringify(getters.getAxCustomFields),
                c_customerAccountNo: payload.order.c_customerAccountNo,
                c_location: payload.order.location,
                c_employeeID: payload.order.employeeID,
                c_supervisorID: payload.order.supervisorID
              }
            });
            return patchOrder;
          })
          .then(() => {
            const updateOrderStatus = dispatch('updateOrderStatus');
            return updateOrderStatus;
          })
          // separated out to delay long enough to see success status
          .then(() => {
            return dispatch('orderProgressBarSuccess');
          })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    orderProgressBarSuccess({ commit }) {
      return new Promise(resolve => {
        commit('updateOrderProgressBar', { percent: 100, text: 'Success', variant: 'success' });
        setTimeout(() => {
          commit('resetOrderProgressBar');
          resolve();
        }, 500)
      })
    },

    // Get Order History
    fetchOrderHistory({ state, commit, rootGetters, dispatch }) {
      const toDate = moment().local().endOf('day');
      const fromDate = moment().local().subtract(90, 'days').startOf('day');
      const selectFilter = '(count,total,hits.(data.(creation_date,order_no,status,c_senderProfile,product_items.(product_name),' +
        'shipments.(shipping_address.(full_name),c_req_ship_date))))'

      const customerFilter = {
        term_query: {
          fields: ["customer_email"],
          operator: "one_of",
          values: [rootGetters['ping/parsedIdToken'].email]
        }
      }

      const chunkSize = 200
      return new Promise((resolve, reject) => {
        dispatch('fetchOrderHistoryBatch', {
          initial: true,
          count: chunkSize,
          start: 0,
          fromDate,
          toDate,
          selectFilter,
          customerFilter,
        })
          .then(response => {
            const total = response.data.total
            const remaining = []
            for (let i = chunkSize; i < total; i += chunkSize) {
              remaining.push(dispatch('fetchOrderHistoryBatch', {
                count: chunkSize,
                start: i,
                fromDate,
                toDate,
                selectFilter,
                customerFilter,
              }))
            }
            return Promise.all(remaining)
          })
          .then((response) => {
            commit('setOrderHistory', state.tempOrderBatch)
            commit('setTempOrderBatch', [])
            resolve(response)
          })
          .catch(reject)
      })
    },

    // Batch Order Search due to OCAPI return limit - only 200 orders per call
    fetchOrderHistoryBatch({ state, commit, dispatch }, payload) {

      const newPayload = {
        count: payload.count || 200, // default is 25 only
        start: payload.start,
        query: {
          filtered_query: {
            query: payload.customerFilter,
            filter: {
              range_filter: {
                field: "creation_date",
                from: payload.fromDate,
                to: payload.toDate,
              }
            }
          }
        },
        select: payload.selectFilter,
        sorts: [
          {
            field: "creation_date",
            sort_order: "desc"
          }
        ]
      }

      return new Promise((resolve, reject) => {
        dispatch('ocapi/fetchOrders', newPayload, { root: true })
          .then(response => {
            if (payload.initial) {
              commit('setTempOrderBatch', response.data.hits)
            } else {
              const batch = [
                ...state.tempOrderBatch,
                ...response.data.hits,
              ]
              commit('setTempOrderBatch', batch)
            }
            resolve(response)
          })
          .catch(reject)
      })
    },
  },
  getters: {
    isFavorite: (state, getters, rootState, rootGetters) => (product_id) => {
      const favorites = rootGetters['ping/parsedIdToken'].userData?.favorites;
      return favorites?.length && favorites?.indexOf(product_id) !== -1
    },
    getSentiments: (state, getters) => {
      const productConfig = getters.getEnabledProductConfig(state.product?.id);
      if (productConfig) {
        return state.libraryItems
          .filter(item => item.c_hbc_MessageType === 'sentiment' && productConfig.disabledSentiments.indexOf(item.id) === -1);
      }
      return state.libraryItems.filter(item => item.c_hbc_MessageType === 'sentiment');
    },
    getMessageStarters: (state, getters) => {
      const productConfig = getters.getEnabledProductConfig(state.product?.id);
      if (productConfig) {
        return state.libraryItems
          .filter(item => item.c_hbc_MessageType === 'messageStarter' && productConfig.disabledMessageStarters.indexOf(item.id) === -1);
      }
      return state.libraryItems.filter(item => item.c_hbc_MessageType === 'messageStarter');
    },
    getSenderProfiles: (state, getters, rootState, rootGetters) => {
      if (rootState.ocapi.customerGroup && 'c_senderProfiles' in rootState.ocapi.customerGroup) {
        return JSON.parse(rootState.ocapi.customerGroup.c_senderProfiles)
          .filter(profile => {
            const profiles = rootGetters['ping/parsedIdToken']?.userData?.sender_profiles;
            return profiles ? profiles.indexOf(profile.profileID) !== -1 : false;
          });
      }
      return [];
    },
    getClientConfig: (state, getters, rootState) => {
      if (rootState.ocapi.customerGroup && 'c_clientConfiguration' in rootState.ocapi.customerGroup) {
        return JSON.parse(rootState.ocapi.customerGroup.c_clientConfiguration);
      }
      return null;
    },
    getCategoryCustomization: (state, getters, rootState) => {
      if (rootState.ocapi.customerGroup && 'c_categoryCustomization' in rootState.ocapi.customerGroup) {
        return JSON.parse(rootState.ocapi.customerGroup.c_categoryCustomization);
      }
      return {};
    },
    getEnabledProducts: (state, getters, rootState) => {
      if (rootState.ocapi.customerGroup && 'c_clientModelLine' in rootState.ocapi.customerGroup) {
        return JSON.parse(rootState.ocapi.customerGroup.c_clientModelLine);
      }
      return [];
    },
    getEnabledProductIds: (state, getters) => {
      if (getters.getEnabledProducts) {
        return getters.getEnabledProducts.map(product => product.id);
      }
      return [];
    },
    enabledProductsObject(state, getters) {
      const enabledProducts = getters.getEnabledProducts;
      const obj = {};
      if (enabledProducts) {
        enabledProducts.forEach(product => {
          obj[product.id] = null;
        })
      }
      return obj;
    },
    getEnabledProductConfig: (state, getters) => (product_id) => {
      return getters.getEnabledProducts.filter(product => product.id === product_id)[0];
    },
    getMatchingProducts: (state, getters, rootState, rootGetters) => (categoryId, languageId) => {
      const products = state.products.filter(product => {
        if (
          product.product_id in getters.enabledProductsObject
          && (languageId === 'all' || languageId === product.c_cardLanguage)
          && product.orderable === true
        ) {
          if (categoryId === state.defaultCategory) {
            return true;
          } else if (categoryId === 'favorites') {
            return rootGetters['ping/parsedIdToken']?.userData?.favorites?.indexOf(product.product_id) !== -1;
          } else {
            return product.c_primaryCategory === categoryId;
          }
        } else {
          return false;
        }
      });
      return products;
    },
    getActiveProducts(state, getters) {
      return getters.getMatchingProducts(state.activeCategory, state.activeLanguage);
    },
    getMergedCategories(state) {
      return state.categories.map(category => {
        state.spanishCategories.forEach(spanishCategory => {
          if (category.id === spanishCategory.id) {
            category.spanishName = spanishCategory.name;
          }
        });
        return category;
      })
    },
    getBlackoutDates(state) {
      return state.blackoutDates.map(date => date.c_date);
    },
    getDefaultShipDate(state) {
      if (state.defaultShipDate) {
        const defaultDateParts = state.defaultShipDate.split('-').map(number => parseInt(number));
        return new Date(defaultDateParts[0], defaultDateParts[1] - 1, defaultDateParts[2]);
      }
      return null;
    },
    getAxCustomFields(state, getters, rootState, rootGetters) {
      const profile = rootGetters['ping/parsedIdToken'].profile;
      const schema = getters.getClientConfig?.customFields?.axCustomFields || [];
      return schema.map(field => {
        const { label } = field;
        let value = field.default;
        const valueVariable = field.value.match(/{{([^}]*)}}/);
        if (valueVariable && profile[valueVariable[1]]) {
          value = profile[valueVariable[1]];
        }
        return {
          label,
          value
        }
      })
    },
    getCustomerAccountNumberObject: (state, getters) => (value) => {
      const schema = getters.getClientConfig?.customFields?.accountNumber || {};
      if (value) {
        return Object.assign(schema, { value });
      }
      return schema;
    }
  }
};
