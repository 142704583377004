import Vue from 'vue'
import Vuex from 'vuex'
import uptodate from './uptodate'
import front from './front';
import ocapi from './ocapi';
import personalization from './personalization';
import ping from './ping';
import router from '../router';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    uptodate,
    front,
    ocapi,
    personalization,
    ping
  },
  state: {
    loading: true,
    error: null,
    debug: process.env.VUE_APP_DEBUG === 'true' ? true : false
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false
    },
    setError(state, payload) {
      state.error = payload;
    }
  },
  actions: {
    handleError({ commit }, payload) {
      commit('setError', payload);
      router.push('error');
    }
  }
});
