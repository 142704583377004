<template>
<div class="layout-front">
  <LoadingOverlay v-if="loading" />
  <b-modal
    id="order-progress-bar"
    v-model="orderProgressBar.show"
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    centered
    content-class="shadow"
    size="lg"
  >
    <b-progress
      :value="orderProgressBar.percent"
      :class="{ 'mt-5' : orderProgressBar.text.length }"
      :variant="orderProgressBar.variant"
      striped
      animated
    />
    <p v-if="orderProgressBar.text.length" class="mt-3 text-center">{{ orderProgressBar.text }}</p>
  </b-modal>

  <div v-if="headerContentSlot" class="bg-dark text-white">
    <b-button v-b-toggle.offers-bar variant="dark" class="rounded-0" block>
      <b-container v-html="headerContentSlot.callout"></b-container>
    </b-button>
    <b-collapse id="offers-bar">
      <b-container class="pb-2" v-html="headerContentSlot.body"/>
    </b-collapse>
  </div>
  
  <b-container>

    <b-navbar toggleable="lg" class="site-header">
      <img :src="logo" class="site-header-logo d-inline-block align-top" alt="logo" />

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown :text="'Hello, '+name" right>
            <b-nav-item @click="openHistory()">Order History</b-nav-item>
            <b-nav-item v-if="includeDashboardLink" :href="reportingURL" target="_blank">Reporting &amp; Admin</b-nav-item>
            <b-nav-item to="logout">Sign Out</b-nav-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown right menu-class="text-danger" v-if="debug === true">
            <template v-slot:button-content>
              <span class="text-danger">DEBUG</span>
            </template>
            <b-dropdown-item @click.prevent="$store.dispatch('placeOrder')">
              test order
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="$store.dispatch('front/deleteAllBaskets')">
              delete baskets
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="$store.dispatch('ping/readUser')">readUser</b-dropdown-item>
            <b-dropdown-item @click.prevent="(function($store){ const { favorites } = $store.getters['ping/parsedIdToken'].userData; let newdata = { favorites }; newdata.languages = ['en_US', 'es']; newdata.sender_profiles = ['hbc']; $store.dispatch('ping/updateUser', { salesforceUserData: newdata }) })($store)">Ping: update user salesforceUserData</b-dropdown-item>
            <b-dropdown-item @click.prevent="(function($store){ $store.dispatch('ping/updateProfile', newProfile) })($store)">Update profile</b-dropdown-item>
            <b-dropdown-item @click.prevent="(function($store){ const { favorites, sender_profiles } = $store.state.ping.salesforceUserData; let newdata = { favorites, sender_profiles }; newdata.languages = ['en_US', 'es']; $store.dispatch('ping/updateOtherUser', { userId: 'c8def554-3c14-4fe5-994a-dc44f58cdb9a', data: { salesforceUserData: newdata } }) })($store)">Ping: Update Another User</b-dropdown-item>
            <b-dropdown-item @click.prevent="$store.dispatch('ocapi/updateCustomer', { c_pingPopulationID: $store.getters['ping/parsedIdToken'].populationID })">add ping Population ID to CC Customer</b-dropdown-item>
            <b-dropdown-item @click.prevent="$store.dispatch('handleError', { title: 'Manually triggered error', message: 'There was an error', data: {test: ['a', 'b', 'c'], thing: 'you already know', another: { items: [], other: [1,2,3] }} })">Test error</b-dropdown-item>
            <b-dropdown-item @click.prevent="$store.dispatch('ping/tokenIntrospection', $store.state.ping.userTokens.id_token)">Introspect ID Token</b-dropdown-item>
            <b-dropdown-item @click.prevent="$store.dispatch('ping/exchangeRefreshToken')">Exchange Refresh Token</b-dropdown-item>
            <b-dropdown-item @click.prevent="$store.dispatch('ping/tokenIntrospection', $store.state.ping.userTokens.access_token)">Introspect Access Token</b-dropdown-item>
            <b-dropdown-item @click.prevent="$store.dispatch('front/fetchBlackoutDates')">Fetch Blackout Dates</b-dropdown-item>
            <b-dropdown-item @click.prevent="$store.dispatch('front/fetchOcapiLoginForCurrentPingUser')">Search For Current User</b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </b-container>

  <b-modal
      modal-class="modal-fullscreen"
      id="modal-fullscreen"
      header-text-variant="light"
      body-text-variant="white"
      hide-footer
      size="xl"
      fade
      v-model="showOrderHistory"
      no-close-on-esc
      no-close-on-backdrop
    >
      <OrderHistory />
  </b-modal>

  <router-view />
</div>
</template>

<script>
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import OrderHistory from '@/components/OrderHistory.vue';
import Cookies from 'js-cookie';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

/* eslint-disable no-console */
export default {
  name: "Home",
  components: {
    LoadingOverlay,
    OrderHistory
  },
  data()
  {
    return {
      showOrderHistory: false
    }
  },
  computed: {
    ...mapState([
      'debug'
    ]),
    ...mapState('front', [
      'loading',
      'orderProgressBar'
    ]),
    ...mapState('ping', [
      'config'
    ]),
    ...mapState('ocapi', [
      'customerGroup'
    ]),
    ...mapGetters('ping', [
      'parsedIdToken'
    ]),
    ...mapGetters('ocapi', [
      'headerContentSlot'
    ]),
    newProfile() {
      let profile = Object.assign({}, this.parsedIdToken.profile);
      profile.role = '';
      return profile;
    },
    includeDashboardLink() {
      const role = this.parsedIdToken?.profile?.role;
      return role === 'admin' || role === 'clientadmin' || role === 'reporting';
    },
    reportingURL(){
      let urlSuffix = "";
      let clientID = Cookies.get('client');
      if (this.config?.policy && clientID) {
        urlSuffix = "sso/"+clientID
      } else if (clientID){
        urlSuffix = clientID
      }
      return process.env.VUE_APP_DASHBOARD_URL + urlSuffix;
    },
    logo() {
      return this.customerGroup?.c_clientLogo?.abs_url || `/images/logos/${this.config.logo}`;
    },
    name() {
      return this.parsedIdToken?.given_name || "";
    }
  },
  methods: {
    ...mapMutations("front", ["startLoading", "stopLoading"]),
    ...mapActions('front', [
      'fetchOrderHistory',
    ]),
    openHistory() {
      this.startLoading();
      this.showOrderHistory = true;
      this.fetchOrderHistory()
        .then(() => {
          this.stopLoading();
        })
        .catch((error) => {
          this.stopLoading();
          console.error(error);
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.site-header {
  height: 75px;
}
.site-header-logo {
  max-height: 60px;
  width: auto;
  display: block;
}
.layout-front {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}


// product borders
$border-color: #e7e7e7;

.product {
  border-top: 1px solid $border-color;

  &:first-child {
    border-top: none;
  }

  @media (min-width: 768px) {
    &:nth-child(2) {
      border-top: none;
    }

    &:nth-child(even) {
      border-left: 1px solid $border-color;
    }
  }

  @media (min-width: 992px) {
    &:nth-child(n) {
      border-left: 0;
    }

    &:nth-child(3) {
      border-top: none;
    }

    &:nth-child(3n),
    &:nth-child(3n - 1) {
      border-left: 1px solid $border-color;
    }
  }

  @media (min-width: 1200px) {
    &:nth-child(n) {
      border-left: 0;
    }

    &:nth-child(4) {
      border-top: none;
    }

    &:nth-child(4n),
    &:nth-child(4n - 1),
    &:nth-child(4n - 2) {
      border-left: 1px solid $border-color;
    }
  }
}

::v-deep .modal-fullscreen {
  padding: 0 !important;
}
::v-deep .modal-fullscreen .modal-header {
  border-radius: 0 !important;
  border-color: transparent;
  background-color: transparent;
}
::v-deep .modal-fullscreen .modal-dialog {
  max-width: 100%;
  height: 100%;
  margin: 0;
}
::v-deep .modal-fullscreen .modal-body {
  background-color: transparent;
}
::v-deep .modal-fullscreen .modal-content {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  min-height: 100%;
  height: auto;
}
::v-deep .modal-fullscreen + .modal-backdrop {
  opacity: 0.8;
}
</style>
<style lang="scss">
  #order-progress-bar___BV_modal_outer_ {
    z-index: 99999999 !important;
  }
</style>