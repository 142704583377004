<template>
  <div>
    <div class="bg-light">
      <b-container>
        <HorizontalScrollNavigation :items="categories" :active="activeCategory" @change="handleNavChange" />
      </b-container>
    </div>

    <b-container style="min-height:70vh;">
      <SelectLanguage v-if="showSelectLanguage" />
      <div v-if="products.length" style="padding: 0px 45px;">
        <ul style="list-style-type:none;" align-center class="row mx-0 px-0">
          <li class="product col-md-6 col-lg-4 col-xl-3 p-4" v-for="(product, index) in products"
            :key="product.product_id">
            <SelectCard :card="product" @click.prevent="openForm(product.product_id)" :index="index" />
          </li>
        </ul>
      </div>
    </b-container>

    <Footer></Footer>

    <b-modal v-model="showConfirmation" ok-only
      title-html="<h2>The card is on its way!</h2><h6>Thanks for showing our customers just how much we care.</h6>"
      no-close-on-esc no-close-on-backdrop size="xl">
      <b-container class="order-confirmation">
        <b-row>
          <b-col cols="4">
            <img class="img-fluid" :src="productImage" alt="" />
          </b-col>
          <b-col class="text-left white-space:pre-line">
            <b-row>
              <b-col>
                <h4>Delivery Address:</h4>
                {{ orderConfirmationDetails.dAddline1 }} <br>
                {{ orderConfirmationDetails.dAddline2 }} <br>
                {{ orderConfirmationDetails.dAddline3 }} <br v-if="orderConfirmationDetails.dAddline3">
                {{ orderConfirmationDetails.dAddline4 }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4>Mail Date:</h4>
                {{ orderConfirmationDetails.shipDate }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4>Confirmation:</h4>
                {{ orderConfirmationDetails.orderNo }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4>Description:</h4>
                {{ orderConfirmationDetails.prodDesc }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal modal-class="modal-preview" v-model="showPreview" no-close-on-esc no-close-on-backdrop
      title="Preview the Card" ok-title="Send" cancel-title="Edit" size="xl" @ok="onSubmit">
      <b-carousel id="carousel-1" :interval="0" controls indicators background="#ababab"
        style="text-shadow: 1px 1px 2px #333">
        <b-carousel-slide v-for="image in previewImages" :key="image">
          <template #img>
            <img style="
                object-fit: contain;
                object-position: center center;
                width: 100%;
                height: 100%;
              " :src="image" />
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-modal>

    <b-modal modal-class="modal-fullscreen" id="modal-fullscreen" header-text-variant="light" body-text-variant="white"
      hide-footer size="xl" fade v-model="showForm" no-close-on-esc no-close-on-backdrop @hidden="onOrderFormHidden">
      <b-container>
        <b-row class="justify-content-center">
          <b-col lg="10">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <h2 class="mb-0">Personalize the card</h2>
              <div class="d-flex align-items-center">
                <p class="mb-0 mr-3">Favorite?</p>
                <b-avatar v-if="product" button icon="star-fill"
                  :variant="isFavorite(product.id) ? 'warning' : 'secondary'" @click="toggleFavorite(product.id)"
                  class="p-0">
                </b-avatar>
              </div>
            </div>
            <b-form @submit.prevent="onSubmit">
              <b-row v-if="senderProfileOptions.length > 0">
                <b-col>
                  <b-form-group>
                    <label for="senderProfile">Sender Profile:</label>
                    <b-form-select id="senderProfile" name="senderProfile" v-model="senderProfile"
                      :options="senderProfileOptions" :disabled="senderProfileOptions.length == 1">
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="accountNumberConfig.enabled && senderProfileOptions.length > 0">
                <b-col>
                  <b-form-group v-if="accountNumberRequiresValidation">
                    <b-input-group>
                      <b-form-input id="accountNumber" v-model="$v.form.accountNumber.$model"
                        :placeholder="accountNumberConfig.label" :disabled="isDisabledField('accountNumber')"
                        :state="validateState('accountNumber')" aria-describedby="accountNumber-live-feedback">
                      </b-form-input>
                      <template v-slot:append>
                        <b-input-group-text>
                          <b-icon icon="person-fill"></b-icon>
                        </b-input-group-text>
                      </template>
                    </b-input-group>
                    <b-form-invalid-feedback id="accountNumber-live-feedback"
                      :class="validateState('accountNumber') === false ? 'd-block' : ''">
                      {{ accountNumberErrorMessage }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group v-else>
                    <b-input-group>
                      <b-form-input id="accountNumber" v-model="form.accountNumber"
                        :placeholder="accountNumberConfig.label" :disabled="isDisabledField('accountNumber')">
                      </b-form-input>
                      <template v-slot:append>
                        <b-input-group-text>
                          <b-icon icon="person-fill"></b-icon>
                        </b-input-group-text>
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-row v-if="hasSenderProfile">
                <b-col md="6">
                  <b-form-group>
                    <b-form-input id="firstName" name="firstName" v-model="$v.form.firstName.$model"
                      :state="validateState('firstName')" placeholder="First Name"
                      aria-describedby="firstName-live-feedback" autocomplete="given-name"
                      :disabled="isDisabledField('firstName')">
                    </b-form-input>
                    <b-form-invalid-feedback id="firstName-live-feedback">
                      {{ firstNameFeedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <b-form-input id="lastName" name="lastName" v-model="$v.form.lastName.$model"
                      :state="validateState('lastName')" placeholder="Last Name"
                      aria-describedby="lastName-live-feedback" autocomplete="family-name"
                      :disabled="isDisabledField('lastName')">
                    </b-form-input>
                    <b-form-invalid-feedback id="lastName-live-feedback">
                      {{ lastNameFeedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-row v-if="hasSenderProfile">
                <b-col>
                  <b-form-group>
                    <b-form-input id="address1" name="address1" v-model="$v.form.address1.$model"
                      :state="validateState('address1')" placeholder="Address" aria-describedby="address1-live-feedback"
                      autocomplete="address-line1" :disabled="isDisabledField('address1')">
                    </b-form-input>
                    <b-form-invalid-feedback id="address1-live-feedback">
                      {{ addressFeedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="hasSenderProfile">
                <b-col>
                  <b-form-group>
                    <b-form-input id="address2" name="address2" v-model="$v.form.address2.$model"
                      :state="validateState('address2')" placeholder="Address2"
                      aria-describedby="address2-live-feedback" autocomplete="address-line2"
                      :disabled="isDisabledField('address2')">
                    </b-form-input>
                    <b-form-invalid-feedback id="address2-live-feedback">
                      {{ address2Feedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-row v-if="hasSenderProfile">
                <b-col md="5">
                  <b-form-group>
                    <b-form-input id="city" name="city" v-model="$v.form.city.$model" :state="validateState('city')"
                      placeholder="City" aria-describedby="city-live-feedback" autocomplete="address-level-2"
                      :disabled="isDisabledField('city')">
                    </b-form-input>
                    <b-form-invalid-feedback id="city-live-feedback">
                      {{ cityFeedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <b-form-select id="state" name="state" v-model="$v.form.state.$model"
                      :state="validateState('state')" :options="stateOptions" aria-describedby="state-live-feedback"
                      autocomplete="address-level-1" :disabled="isDisabledField('state')">
                    </b-form-select>
                    <b-form-invalid-feedback id="state-live-feedback">
                      Please select a state.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <b-form-input id="postal-code" name="postal-code" v-model="$v.form.zipCode.$model"
                      :state="validateState('zipCode')" placeholder="ZIP / Postal Code"
                      aria-describedby="zipCode-live-feedback" autocomplete="postal-code" @input="formatZipcode"
                      :disabled="isDisabledField('zipCode')">
                    </b-form-input>
                    <b-form-invalid-feedback id="zipCode-live-feedback">
                      {{ zipCodeFeedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-row v-if="this.getSentiments.length > 1 && hasSenderProfile">
                <b-col>
                  <b-form-group>
                    <b-form-select id="sentiment" name="sentiment" v-model="$v.form.sentiment.$model"
                      :state="validateState('sentiment')" :options="sentimentOptions"
                      aria-describedby="sentiment-live-feedback">
                    </b-form-select>
                    <b-form-invalid-feedback id="sentiment-live-feedback">
                      Please select a sentiment.
                    </b-form-invalid-feedback>

                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="presetClosingOptions.length > 1 && hasSenderProfile">
                <b-col>
                  <b-form-group>
                    <b-form-select id="presetClosing" name="presetClosing" v-model="presetClosing"
                      :options="presetClosingOptions" @change="handlePresetClosingChange">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="showPersonalClosing && hasSenderProfile">
                <b-col>
                  <b-form-group v-bind:style="{ marginBottom: '0px' }">
                    <b-form-textarea id="closing" name="closing" v-model="$v.form.closing.$model"
                      :state="validateState('closing')" aria-describedby="closing-live-feedback" placeholder="Message"
                      :disabled="disablePersonalClosing" maxlength="270">
                    </b-form-textarea>
                    <span class="limiter">{{ charactersRemaining }}</span>
                    <b-form-invalid-feedback id="closing-live-feedback">
                      {{ closingFeedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>{{ form.selectedMessage }}</b-col>
              </b-row>

              <b-row v-if="hasSenderProfile">
                <b-col>
                  <b-form-group>
                    <div>
                      <label for="sendDate" style="margin: 5px 0px;">Future ship date must be after customer's move in
                        date</label>
                      <b-form-datepicker id="sendDate" :date-disabled-fn="dateDisabled" :min="minDate" :max="maxDate"
                        v-model="$v.form.sendDate.$model" :disabled="isDisabledField('sendDate')" />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="my-3" v-if="!hasSenderProfile">
                <b-col>
                  <h4 class="bg-white text-danger p-2">Account Error</h4>
                  <p>
                    Your user account is missing a sender profile. At least one sender profile must be assigned to send
                    or
                    preview a card. Please contact your Hallmark program administrator to update your account.
                  </p>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-button :disabled="!hasSenderProfile" variant="secondary" block @click.prevent="openPreview">
                    Preview
                  </b-button>
                </b-col>
                <b-col>
                  <b-button :disabled="!hasSenderProfile" variant="primary" block type="submit">Send</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-console */
import HorizontalScrollNavigation from '@/components/HorizontalScrollNavigation';
import SelectCard from "@/components/SelectCard.vue";
import SelectLanguage from "@/components/SelectLanguage.vue";
import Footer from "@/layouts/Footer.vue";
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Cookies from 'js-cookie';
import ProfanityFilter from 'bad-words';
import store from '../store/index';
const profanityFilter = new ProfanityFilter();
//removal of words that clients need
profanityFilter.removeWords('dyke', 'dick', 'cox', 'wang', 'schaffer', 'gaylord', 'god', 'god bless', 'coon');

const numbersOnlyRegex = new RegExp(/^\d+$/);
const hyphenZipCodeStartRegex = new RegExp(/^\d{5}-\d+$/);
const hyphenZipCodeRegex = new RegExp(/^\d{5}-\d{4}$/);

const maxBaskets = 1;

const lineMaxLength = 55;
const cityMaxLength = lineMaxLength - ' XX, XXXXX-XXXX'.length;

export default {
  name: "Home",
  components: {
    HorizontalScrollNavigation,
    SelectCard,
    SelectLanguage,
    Footer
  },
  mixins: [validationMixin],
  data() {
    return {
      errors: [],
      showForm: false,
      showPreview: false,
      showConfirmation: false,
      previewImages: [],
      presetClosing: null,
      disabledFields: [],
      senderProfile: null,
      storeRecipientData: false,
      minDate: '',
      maxDate: '',
      envImage: '/images/envelope.png',
      stateOptions: [
        { value: '', text: "State/Province" },
        { value: "AL", text: "Alabama" },
        { value: "AK", text: "Alaska" },
        { value: "AZ", text: "Arizona" },
        { value: "AR", text: "Arkansas" },
        { value: "CA", text: "California" },
        { value: "CO", text: "Colorado" },
        { value: "CT", text: "Connecticut" },
        { value: "DE", text: "Delaware" },
        { value: "FL", text: "Florida" },
        { value: "GA", text: "Georgia" },
        { value: "HI", text: "Hawaii" },
        { value: "ID", text: "Idaho" },
        { value: "IL", text: "Illinois" },
        { value: "IN", text: "Indiana" },
        { value: "IA", text: "Iowa" },
        { value: "KS", text: "Kansas" },
        { value: "KY", text: "Kentucky" },
        { value: "LA", text: "Louisiana" },
        { value: "ME", text: "Maine" },
        { value: "MD", text: "Maryland" },
        { value: "MA", text: "Massachusetts" },
        { value: "MI", text: "Michigan" },
        { value: "MN", text: "Minnesota" },
        { value: "MO", text: "Missouri" },
        { value: "MS", text: "Mississippi" },
        { value: "MT", text: "Montana" },
        { value: "NE", text: "Nebraska" },
        { value: "NV", text: "Nevada" },
        { value: "NH", text: "New Hampshire" },
        { value: "NJ", text: "New Jersey" },
        { value: "NM", text: "New Mexico" },
        { value: "NY", text: "New York" },
        { value: "NC", text: "North Carolina" },
        { value: "ND", text: "North Dakota" },
        { value: "OH", text: "Ohio" },
        { value: "OK", text: "Oklahoma" },
        { value: "OR", text: "Oregon" },
        { value: "PA", text: "Pennsylvania" },
        { value: "RI", text: "Rhode Island" },
        { value: "SC", text: "South Carolina" },
        { value: "SD", text: "South Dakota" },
        { value: "TN", text: "Tennessee" },
        { value: "TX", text: "Texas" },
        { value: "UT", text: "Utah" },
        { value: "VT", text: "Vermont" },
        { value: "VA", text: "Virginia" },
        { value: "WA", text: "Washington" },
        { value: "DC", text: "Washington DC" },
        { value: "WV", text: "West Virginia" },
        { value: "WI", text: "Wisconsin" },
        { value: "WY", text: "Wyoming" },
        { value: "GU", text: "Guam" },
        { value: "PR", text: "Puerto Rico" },
        { value: "VI", text: "Virgin Islands" },
        { value: "MP", text: "Northern Mariana Islands" },
        { value: "AS", text: "American Samoa" },
        { value: "MH", text: "Marshall Islands" },
        { value: "PW", text: "Palau" },
        {
          value: "AE",
          text: "AE - Armed Forces Europe, the Middle East, Canada",
        },
        { value: "AP", text: "AP - Armed Forces Pacific" },
        { value: "AA", text: "AA - Armed Forces America (except Canada)" },
      ],
      form: {
        accountNumber: "",
        lastName: "",
        firstName: "",
        address1: "",
        address2: "",
        sentiment: null,
        closing: null,
        city: '',
        state: '',
        zipCode: '',
        sendDate: '',
      },
    };
  },
  validations() {
    const form = {
      form: {
        lastName: {
          required,
          notProfane: this.notProfane
        },
        firstName: {
          required,
          notProfane: this.notProfane
        },
        address1: {
          required,
          maxLength: maxLength(lineMaxLength)
        },
        address2: {
          maxLength: maxLength(lineMaxLength)
        },
        city: {
          required,
          maxLength: maxLength(cityMaxLength)
        },
        state: {
          required,
        },
        zipCode: {
          required,
          validZipcode: this.validZipcode
        },
        sendDate: {
          required,
        }
      }
    };
    if (this.getSentiments.length) {
      form.form.sentiment = {
        required
      }
    }
    if (this.showPersonalClosing) {
      form.form.closing = {
        required,
        notProfane: this.notProfane
      };
    }
    if (this.accountNumberRequiresValidation) {
      let validations = {};
      if (this.accountNumberConfig.required) {
        validations = { ...validations, required };
      }
      if (this.accountNumberConfig.numeric) {
        validations = { ...validations, numeric: this.isNumeric }
      }
      if (this.accountNumberConfig.maxLength > 0) {
        validations = { ...validations, shortEnough: this.accountNumberIsShortEnough }
      }
      form.form.accountNumber = validations;
    }
    return form;
  },
  computed: {
    ...mapGetters('front', [
      'getActiveProducts',
      'getMatchingProducts',
      'getMergedCategories',
      'getSentiments',
      'getMessageStarters',
      'getSenderProfiles',
      'getDefaultShipDate',
      'getClientConfig',
      'getCategoryCustomization',
      'getCustomerAccountNumberObject',
      'isFavorite'
    ]),
    ...mapGetters('ping', [
      'parsedIdToken'
    ]),
    ...mapState('front', [
      'activeCategory',
      'activeLanguage',
      'loading',
      'product',
      'order',
      'categories'
    ]),
    hasSenderProfile() {
      return this.senderProfile?.length > 0
    },
    products() {
      return this.getActiveProducts;
    },
    categories() {
      return [
        {
          name: 'View All',
          id: this.$store.state.front.defaultCategory
        },
        {
          name: 'Favorites',
          id: 'favorites'
        },
        ...this.getMergedCategories
      ].map(category => {
        const categoryCustomization = this.getCategoryCustomization;
        this.$set(category, 'disabled', !this.hasProducts(category.id, this.activeLanguage));
        if (category.id in categoryCustomization) {
          const englishName = categoryCustomization[category.id]?.english;
          const spanishName = categoryCustomization[category.id]?.spanish;
          if (englishName) {
            this.$set(category, 'name', englishName);
          }
          if (spanishName) {
            this.$set(category, 'spanishName', spanishName)
          }
        }
        return category;
      });
    },
    showSelectLanguage() {
      return this.$store.getters['ping/parsedIdToken']?.userData?.languages?.length > 1
    },
    accountNumberConfig() {
      const accountNumber = this.getClientConfig?.customFields?.accountNumber;
      return {
        label: accountNumber?.label || 'Customer Account',
        enabled: accountNumber?.enabled || false,
        required: accountNumber?.required || false,
        numeric: accountNumber?.numeric || false,
        maxLength: accountNumber?.maxLength || 0
      }
    },
    accountNumberRequiresValidation() {
      return this.accountNumberConfig.enabled && (this.accountNumberConfig.required || this.accountNumberConfig.numeric || this.accountNumberConfig.maxLength > 0);
    },
    accountNumberErrorMessage() {
      const label = this.accountNumberConfig.label;
      if (this.accountNumberConfig.numeric && !this.isNumeric(this.form.accountNumber)) {
        return `${label} must be a number.`;
      }
      if (!this.accountNumberIsShortEnough(this.form.accountNumber)) {
        return `${label} can be no more than ${this.accountNumberConfig.maxLength} characters long.`;
      }
      if (this.accountNumberConfig.required) {
        return `${label} is required.`;
      }
      return '';
    },
    lastNameFeedback() {
      if (this.isProfane(this.form.lastName)) {
        return 'Please remove any possibly offending words.';
      }
      return 'Please enter a last name.';
    },
    firstNameFeedback() {
      if (this.isProfane(this.form.firstName)) {
        return 'Please remove any possibly offending words.';
      }
      return 'Please enter a first name.';
    },
    addressFeedback() {
      const length = this.$v.form.address1.$model.length;
      const lengthOver = length - lineMaxLength;
      return length > lineMaxLength ? `Address must be ${lineMaxLength} characters or less. Please remove ${lengthOver} character${lengthOver === 1 ? '' : 's'}.` : 'Please enter an address.';
    },
    address2Feedback() {
      const length = this.$v.form.address2.$model.length;
      const lengthOver = length - lineMaxLength;
      return length > lineMaxLength ? `Address line 2 must be ${lineMaxLength} characters or less. Please remove ${lengthOver} character${lengthOver === 1 ? '' : 's'}.` : '';
    },
    cityFeedback() {
      const length = this.$v.form.city.$model.length;
      const lengthOver = length - cityMaxLength;
      return length > cityMaxLength ? `City must be ${cityMaxLength} characters or less. Please remove ${lengthOver} character${lengthOver === 1 ? '' : 's'}.` : 'Please enter a city.';
    },
    closingFeedback() {
      if (this.isProfane(this.form.closing)) {
        return "We're unable to print your message as written. Please remove any possibly offending words.";
      }
      return 'Personal closing is required.';
    },
    senderProfileOptions() {
      return this.getSenderProfiles.map((profile) => {
        return {
          value: profile.profileID,
          text: profile.profileName,
        };
      });
    },
    selectedSenderProfile() {
      return this.getSenderProfiles.filter(
        (profile) => profile.profileID === this.senderProfile
      )[0];
    },
    sentimentOptions() {
      const sentiments = [
        ...this.getSentiments.map((sentiment) => ({
          value: sentiment.id,
          text: sentiment.description.default,
        }))
      ];
      if (sentiments.length > 1) {
        return [
          {
            value: null,
            text: "Select a sentiment",
          },
          ...sentiments
        ];
      }
      return sentiments;
    },
    presetClosingOptions() {
      return [
        {
          value: null,
          text: "Choose personal closing",
        },
        ...this.getMessageStarters.map((closing) => ({
          value: closing.id,
          text: closing.description.default,
        })),
      ];
    },
    selectedSentiment() {
      if (!this.form.sentiment) {
        return null;
      }
      return this.$store.state.front.libraryItems.find(
        (item) =>
          item.c_hbc_MessageType === "sentiment" &&
          item.id === this.form.sentiment
      ) || null;
    },
    selectedClosing() {
      if (!this.presetClosing) {
        return null;
      }
      return this.$store.state.front.libraryItems.filter(
        (item) =>
          item.c_hbc_MessageType === "messageStarter" &&
          item.id === this.presetClosing
      )[0];
    },
    productImage() {
      return this.products.find(product => this.product?.id === product.product_id)?.image?.link || '';
    },
    showPersonalClosing() {
      const disableEditPersonalClosing = this.$store.getters['front/getClientConfig']?.disableEditPersonalClosing;
      return this.presetClosingOptions.length > 1 || disableEditPersonalClosing !== true;
    },
    disablePersonalClosing() {
      return this.$store.getters['front/getClientConfig']?.disableEditPersonalClosing === true;
    },
    cardSize() {
      return this.product.c_hbc_CardSize;
    },
    orientation() {
      return this.product.c_hbc_CardOrientation;
    },
    sentimentUrl() {
      return this.selectedSentiment?.c_hbc_SentimentImage?.abs_url || '';
    },
    logoUrl() {
      return null;
    },
    personalizationName() {
      return this.form.firstName;
    },
    signature() {
      const idToken = this.$store.getters["ping/parsedIdToken"];
      const replace = {
        '{{firstname}}': idToken.given_name,
        '{{lastname}}': idToken.family_name,
        '{{firstinitial}}': idToken.given_name.charAt(0),
        '{{lastinitial}}': idToken.family_name.charAt(0),
        '{{location}}': this.getClientConfig?.locations?.find(location => idToken?.profile?.location === location.value)?.label,
        '{{senderProfileName}}': this.selectedSenderProfile?.profileName
      };
      let signature = this.selectedSenderProfile.signature;
      for (let key in replace) {
        if (!replace.hasOwnProperty(key)) {
          continue;
        }
        signature = signature.replace(new RegExp(key, 'g'), replace[key]);
      }
      return signature;
    },
    indentedSignature() {
      const indent = '\t\t\t\t\t';
      return `${indent}${this.signature.replace(new RegExp('\\n', 'g'), `\n${indent}`)}`;
    },
    shippingAddress() {
      const {
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        zipCode,
      } = this.form;
      return {
        first_name: firstName,
        last_name: lastName,
        address1,
        address2,
        city,
        state_code: state,
        postal_code: zipCode,
        country_code: 'US',
      };
    },
    billingAddress() {
      const {
        name,
        address1,
        address2,
        city,
        state,
        zip,
        country,
      } = this.selectedSenderProfile.returnAddress;
      return {
        first_name: this.parsedIdToken?.given_name,
        last_name: this.parsedIdToken?.family_name,
        address1,
        address2,
        city,
        state_code: state,
        postal_code: zip,
        company_name: name,
        country_code: country,
      };
    },
    returnAddress() {
      const {
        name,
        address1,
        address2,
        city,
        state,
        zip,
        country,
      } = this.selectedSenderProfile.returnAddress;
      return {
        ContentType: "ReturnAddress",
        FirstName: this.$store.getters["ping/parsedIdToken"]?.given_name,
        LastName: this.$store.getters["ping/parsedIdToken"]?.family_name,
        Address1: address1,
        Address2: address2,
        City: city,
        State: state,
        PostalCode: zip,
        CompanyName: name,
        Country: country,
        FontSize: 21,
      };
    },
    recipientAddressForEnvelopePreview() {
      const {
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        zipCode,
      } = this.form;
      return [
        `${firstName} ${lastName}`,
        `${address1}`,
        `${address2}`,
        `${city}, ${state} ${zipCode}`
      ].filter(line => {
        // remove empty lines
        const newLine = line.replace(/\W/g, '');
        return newLine !== '' && newLine !== 'null';
      }).join('\n');
    },
    returnAddressForEnvelopePreview() {
      if (this.selectedSenderProfile?.returnAddress) {
        const {
          name,
          address1,
          address2,
          city,
          state,
          zip,
        } = this.selectedSenderProfile.returnAddress;
        return [
          `${name}`,
          `${address1}`,
          `${address2}`,
          `${city}, ${state} ${zip}`
        ].filter(line => {
          // remove empty lines
          const newLine = line.replace(/\W/g, '');
          return newLine !== '' && newLine !== 'null';
        }).join('\n');
      } else {
        return '';
      }
    },
    message() {
      return [
        `${this.personalizationName},`,
        "\n\t",
        this.form.closing,
        "\n\n",
        this.indentedSignature,
      ].join("");
    },
    insideOfTheCardUrl() {
      return this.product.image_groups.filter(
        (group) => group.view_type === "rs"
      )[0].images[0].link;
    },
    outsideOfTheCardUrl() {
      return this.product.image_groups.filter(
        (group) => group.view_type === "fs"
      )[0].images[0].link;
    },
    enableBackCardContent() {
      if (this.$store.getters['front/getClientConfig']?.enableBackCardContent) {
        return true;
      }
      return false
    },
    backCardMessage() {
      if (this.enableBackCardContent) {
        return this.$store.getters['front/getClientConfig']?.backCardMessage || '';
      }
      return null
    },
    backCardLogo() {
      if (this.enableBackCardContent) {
        var defaultLogo = this.$store.state.ocapi.customerGroup?.c_clientLogo?.abs_url || null;
        var specificLogo = this.selectedSenderProfile?.specificLogo || null;
        if (specificLogo && defaultLogo) {
          return defaultLogo.substring(0, defaultLogo.lastIndexOf('/')) + specificLogo.substring(specificLogo.lastIndexOf('/'))
        }
        return defaultLogo
      }
      return null
    },
    backCardDisclaimer() {
      var defaultDisclaimer = this.$store.getters['front/getClientConfig']?.backCardDisclaimer || null;
      var specificDisclaimer = this.selectedSenderProfile?.specificDisclaimer || null;
      if (this.product.c_hbc_CardLanguage == "es") {
        return specificDisclaimer?.es || defaultDisclaimer?.es || specificDisclaimer?.default || defaultDisclaimer?.default || null;
      } else {
        return specificDisclaimer?.default || defaultDisclaimer?.default || null;
      }
    },
    orderConfirmationDetails() {
      if (this.order) {
        const deliveryAddress = this.order.shipments[0].shipping_address
        return {
          orderNo: this.order.order_no,
          shipDate: this.order.shipments[0].c_req_ship_date,
          prodDesc: this.order.product_items[0].product_name,
          dAddline1: deliveryAddress.full_name,
          dAddline2: deliveryAddress.address1,
          dAddline3: deliveryAddress.address2,
          dAddline4: deliveryAddress.city + ', ' + deliveryAddress.state_code + ' ' + deliveryAddress.postal_code,
        }
      } else {
        return {}
      }
    },
    charactersRemaining() {
      var char = (this.form.closing) ? this.form.closing.length : 0,
        limit = 270;
      return (limit - char) + " characters left";
    },
    zipCodeFeedback() {
      if (this.form.zipCode?.length) {
        return 'Please enter a valid zip code.';
      }
      return 'Please enter a zip code.'
    },
    taxClassCode() {
      const taxExempt = this.getClientConfig?.customFields?.taxExempt
      if (taxExempt?.enabled && taxExempt?.states?.includes(this.shippingAddress.state_code)) {
        return taxExempt.code
      }
      return ''
    }
  },
  methods: {
    ...mapActions('front', [
      'init',
      'setActiveCategory',
      'fetchProductInfo',
      'placeOrder',
      'updateOrderProgressBar'
    ]),
    ...mapActions('ocapi', [
      'fetchCustomerBaskets',
      'deleteBasket'
    ]),
    ...mapMutations({
      startAppLoading: 'startLoading',
      stopAppLoading: 'stopLoading'
    }),
    ...mapMutations('front', [
      'startLoading',
      'stopLoading'
    ]),
    ...mapActions('personalization', [
      'personalizeCard',
      'previewEnvelope'
    ]),
    hasProducts(categoryId, languageId) {
      return this.getMatchingProducts(categoryId, languageId).length > 0;
    },
    handleNavChange(id) {
      this.setActiveCategory(id);
    },
    isNumeric(val) {
      return val.length === 0 || numbersOnlyRegex.test(val);
    },
    isShortEnough(val, maxLength) {
      if (maxLength && maxLength > 0) {
        return val.length <= maxLength;
      }
      return true;
    },
    accountNumberIsShortEnough(val) {
      return this.isShortEnough(val, this.accountNumberConfig.maxLength);
    },
    isProfane(value) {
      return profanityFilter.isProfane(value);
    },
    notProfane(value) {
      return !this.isProfane(value)
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    dateDisabled(ymd, date) {
      // exclude weekends
      if (date.getDay() === 0 || date.getDay() === 6) {
        return true;
        // too far in the past
      } else if (this.minDate.getTime() > date.getTime()) {
        return true;
        // too far in the future
      } else if (this.maxDate.getTime() < date.getTime()) {
        return true;
        // explicitly excluded 
      } else if (this.$store.getters['front/getBlackoutDates'].indexOf(ymd) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    onOrderFormHidden() {
      if (this.storeRecipientData) {
        const {
          accountNumber,
          firstName,
          lastName,
          address1,
          address2,
          city,
          state,
          zipCode,
          sendDate
        } = this.form;

        Cookies.set('recipientData', JSON.stringify({
          accountNumber,
          firstName,
          lastName,
          address1,
          address2,
          city,
          state,
          zipCode,
          sendDate
        }));
      }
      this.resetForm();
      this.$store.commit('front/resetOrderProgressBar');
    },
    checkBaskets() {
      return new Promise((resolve, reject) => {
        const fetchCustomerBaskets = this.fetchCustomerBaskets();
        fetchCustomerBaskets
          .then(baskets => {
            if (baskets.length >= maxBaskets) {
              return this.confirmDeleteBaskets(baskets);
            }
            return Promise.resolve();
          })
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    confirmDeleteBaskets(baskets) {
      this.stopLoading();
      return new Promise((resolve, reject) => {
        this.$bvModal.msgBoxConfirm("It appears you have an order in progress in another window or you didn't complete an order previously. Do you want to cancel those orders to send this one?", {
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          hideHeaderClose: false,
          centered: true,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true
        })
          .then(value => {
            if (value) {
              this.startLoading();
              const deleteRequests = [];
              baskets.forEach(basket => {
                deleteRequests.push(this.deleteBasket(basket.basket_id));
              })
              return Promise.all(deleteRequests);
            }
            reject();
          })
          .then(resolve)
          .catch(reject)
      })
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.startLoading();
      this.checkBaskets()
        .then(() => {
          this.stopLoading();
          this.doOrder();
        })
        .catch(error => {
          console.error(error);
          this.stopLoading();
        })
    },
    doOrder() {
      let currentCategoryObj = this.categories.filter(category => category.id == this.product.primary_category_id);
      let currentCategory = this.product.c_hbc_CardLanguage == "es" ? currentCategoryObj[0].spanishName : currentCategoryObj[0].name;
      this.placeOrder({
        personalization: {
          render: "print",
          cardSize: this.cardSize,
          orientation: this.orientation,
          personalization: [
            {
              sideOfCard: "outside",
              pageTemplate: this.outsideOfTheCardUrl,
              rightOrBottomOfPage: {
                images: [
                  {
                    url: this.backCardLogo
                  }
                ],
                messages: [
                  {
                    text: this.backCardMessage,
                    font: 'garamond10Center'
                  },
                  {
                    text: this.backCardDisclaimer,
                    font: 'whitneybook11Center'
                  }
                ],
                barcodes: [
                  {
                    text: this.$store.state.front.recipient.scanCode
                  }
                ]
              }
            },
            {
              sideOfCard: "inside",
              pageTemplate: this.insideOfTheCardUrl,
              rightOrBottomOfPage: {
                images: [
                  {
                    url: this.sentimentUrl
                  },
                  {
                    url: this.logoUrl
                  }
                ],
                messages: [
                  {
                    text: this.message,
                    font: 'johnson14Left'
                  }
                ]
              }
            }
          ],
        },
        returnAddress: this.returnAddressForEnvelopePreview,
        recipientAddress: this.recipientAddressForEnvelopePreview,
        item: {
          product_id: this.product.id,
          c_ax_item_id: this.product.c_hbc_AxItemID,
          c_ax_envelope_id: this.product.c_hbc_AXIDEnvelope,
          c_hbc_PersonalizationObject: JSON.stringify({
            sampleJSON: "sample",
          }),
          c_sentiment: this.selectedSentiment?.description?.default || '',
          c_sentimentCode: this.selectedSentiment?.id || '',
          c_personalizationName: this.personalizationName,
          c_hbc_personalizationMessage: this.form.closing,
          c_signature: this.signature,
          c_envelopeFontFamily: "JohnsonPrint",
          c_envelopeFontColor: "#000000",
          c_productCategory: currentCategory,
          c_productLanguage: this.product.c_hbc_CardLanguage,
          c_hbc_returnAddress: JSON.stringify(this.returnAddress),
          c_scanCode: this.$store.state.front.recipient.scanCode,
          c_hbc_FulfillmentCenterId: this.product.c_hbc_ShipNode
        },
        order: {
          shipping_address: this.shippingAddress,
          billing_address: this.billingAddress,
          sender_profile: this.selectedSenderProfile,
          c_req_ship_date: this.form.sendDate,
          c_customerAccountNo: JSON.stringify(this.getCustomerAccountNumberObject(this.form.accountNumber)),
          c_taxClassCode: this.taxClassCode || '',
          location: this.$store.getters['ping/parsedIdToken'].profile.location,
          employeeID: this.$store.getters['ping/parsedIdToken'].profile.employeeID,
          supervisorID: this.$store.getters['ping/parsedIdToken'].profile.supervisorID
        }
      })
        .then(() => {
          Cookies.remove("recipientData");
          this.storeRecipientData = false;
          let autoLogout = (this.$store.getters['front/getClientConfig']?.singleSendSSO && this.$store.getters['ping/parsedIdToken'].recipientAddress1);
          this.$store.dispatch('ping/updateUser', {
            recipientAddress1: null,
            recipientAddress2: null,
            recipientCity: null,
            recipientState: null,
            recipientPostalCode: null,
            recipientCountry: null,
            recipientFirstName: null,
            recipientLastName: null,
            accountNumber: null
          });
          const recipient = {
            customer_id: '',
            scanCode: '',
            shipping_address: {
              address1: '',
              address2: '',
              city: '',
              company_name: '',
              country_code: '',
              first_name: '',
              last_name: '',
              phone: '',
              postal_code: '',
              state_code: ''
            }
          }
          this.$store.commit('front/setRecipient', recipient);
          this.showConfirmation = true;
          this.showForm = false;
          //track purchase
          this.$gtm.trackEvent({
            event: 'purchase',
            transaction_id: this.order.order_no,
            affiliation: this.$store.state.ocapi.customerGroup.description,
            value: this.order.order_total,
            currency: 'USD',
            tax: this.order.tax_total,
            shipping: this.order.shipping_total,
            items: [
              {
                item_id: this.order.product_items[0].product_id,
                item_name: this.order.product_items[0].product_name,
                item_category: (this.$store.state.front.activeCategory === 'root') ? 'view-all' : this.$store.state.front.activeCategory,
                quantity: this.order.product_items[0].quantity,
                price: this.order.product_items[0].price_after_order_discount
              }
            ]
          })

          //Logging them off if Single Send SSO was turned on in Client Config and they
          //came over to us with contact info in their SAML assertion
          if (autoLogout) {
            setTimeout(function () {
              store.dispatch('ping/doLogout');
            }, 5000)
          }

        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('front/resetOrderProgressBar');
        });
    },
    initializeForm() {
      let accountNumber = this.$store.state.front.recipient.customer_id;
      let {
        first_name,
        last_name,
        address1,
        address2,
        city,
        state_code,
        postal_code,
      } = this.$store.state.front.recipient.shipping_address;
      let sendDate = this.getInitialSendDate();
      let disabledFields = [];
      let form = {
        accountNumber: "",
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        sentiment: this.sentimentOptions.length ? this.sentimentOptions[0].value : '',
        closing: null,
        sendDate: sendDate,
      };
      var data = Cookies.get('recipientData');
      if (data != null) {
        data = JSON.parse(data);
        form.accountNumber = data.accountNumber;
        form.firstName = data.firstName;
        form.lastName = data.lastName;
        form.address1 = data.address1;
        form.address2 = data.address2;
        form.city = data.city;
        form.state = data.state;
        form.zipCode = data.zipCode;
        form.sendDate = data.sendDate;
      }

      let formValuesMap = [
        {
          prop: "accountNumber",
          val: accountNumber,
        },
        {
          prop: "firstName",
          val: first_name,
        },
        {
          prop: "lastName",
          val: last_name,
        },
        {
          prop: "address1",
          val: address1,
        },
        {
          prop: "address2",
          val: address2,
        },
        {
          prop: "city",
          val: city,
        },
        {
          prop: "state",
          val: state_code,
        },
        {
          prop: "zipCode",
          val: postal_code,
        }
      ];
      formValuesMap.forEach((field) => {
        if (field.val) {
          if (field.prop == "zipCode" && field.val.length > 5) {
            form[field.prop] = this.formatZipcode(field.val);
          } else {
            form[field.prop] = field.val;
          }
          //disabledFields.push(field.prop);
        }
      });
      if (this.getDefaultShipDate) {
        disabledFields.push('sendDate');
      }
      this.form = form;
      this.disabledFields = disabledFields;
    },
    isDisabledField(field) {
      return this.disabledFields.indexOf(field) !== -1;
    },
    handlePresetClosingChange() {
      this.form.closing = this.selectedClosing?.c_hbc_MessageStarter || '';
    },
    openForm(product_id) {
      this.startLoading();
      this.storeRecipientData = true;
      this.fetchProductInfo(product_id)
        .then(() => {
          this.initializeForm();
          this.showForm = true;
          this.stopLoading();
        })
        .catch((error) => {
          this.stopLoading();
          console.error(error);
        });
    },
    resetForm() {
      this.startLoading();

      this.form = {
        accountNumber: '',
        lastName: '',
        firstName: '',
        address1: '',
        address2: '',
        sentiment: null,
        closing: null,
        city: '',
        state: '',
        zipCode: '',
        sendDate: '',
      };
      this.presetClosing = null;

      // this.initializeForm();
      this.$nextTick(() => {
        this.$v.$reset();
        this.stopLoading();
      });
    },
    openPreview() {
      this.startLoading();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.stopLoading();
        return;
      }
      let previewImages = [];
      // front of card - Disabled to use the image returned by api
      /* const frontOfCard = this.product.image_groups.filter(
        (group) => group.view_type === "large"
      )[0].images[0].link; */
      let frontOfCard, insideOfCard, backOfCard, envelope;
      Promise.all([
        this.personalizeCard({
          render: "preview",
          cardSize: this.cardSize,
          orientation: this.orientation,
          bleed: 46,
          singlePanelCrop: true,
          personalization: [
            {
              sideOfCard: "inside",
              pageTemplate: this.insideOfTheCardUrl,
              rightOrBottomOfPage: {
                images: [
                  {
                    url: this.sentimentUrl
                  },
                  {
                    url: this.logoUrl
                  }
                ],
                messages: [
                  {
                    text: this.message,
                    font: 'johnson14Left'
                  }
                ],
                barcodes: []
              }
            }
          ],
        })
          .then((response) => {
            insideOfCard = response.data.jpegDetails?.imageUrl;
            return Promise.resolve();
          }),
        this.personalizeCard({
          render: "preview",
          cardSize: this.cardSize,
          orientation: this.orientation,
          bleed: 46,
          GetSurfaceImages: true,
          personalization: [
            {
              sideOfCard: "outside",
              pageTemplate: this.outsideOfTheCardUrl,
              rightOrBottomOfPage: {
                images: [
                  {
                    url: this.backCardLogo
                  }
                ],
                messages: [
                  {
                    text: this.backCardMessage,
                    font: 'garamond10Center'
                  },
                  {
                    text: this.backCardDisclaimer,
                    font: 'whitneybook11Center'
                  }
                ],
                barcodes: [
                  {
                    text: this.$store.state.front.recipient.scanCode
                  }
                ],
              }
            }
          ]
        })
          .then((response) => {
            frontOfCard = response.data.primaryJpegDetails?.imageUrl;
            backOfCard = response.data.secondaryJpegDetails?.imageUrl;
            return Promise.resolve();
          }),
        this.previewEnvelope({
          returnAddress: this.returnAddressForEnvelopePreview,
          recipientAddress: this.recipientAddressForEnvelopePreview,
        })
          .then(response => {
            envelope = response.data.jpegDetails.imageUrl;
          })
      ])
        .then(() => {
          previewImages = [
            frontOfCard,
            insideOfCard,
            backOfCard,
            envelope
          ];
          this.previewImages = previewImages;
          this.showPreview = true;
          this.stopLoading();
        })
        .catch((error) => {
          this.stopLoading();
          console.error(error);
        });
    },
    close() {
      this.$emit("close");
    },
    toggleFavorite(product_id) {
      this.$store.dispatch("ping/toggleFavorite", product_id);
    },
    setInitialSenderProfile() {
      if (this.senderProfileOptions.length) {
        this.senderProfile = this.senderProfileOptions[0].value;
      } else {
        this.senderProfile = null;
      }
    },
    getFormattedDateString(date) {
      // add leading zeros
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      return `${date.getFullYear()}-${month}-${day}`;
    },
    getInitialSendDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let initialDate = today;

      const defaultDate = this.getDefaultShipDate;
      if (defaultDate && defaultDate.getTime() >= this.minDate.getTime() && this.maxDate.getTime() >= defaultDate.getTime()) {
        initialDate = defaultDate;
      }
      if (!this.dateDisabled(this.getFormattedDateString(initialDate), initialDate)) {
        return this.getFormattedDateString(initialDate);
      } else {
        // increment by 1 day until not disabled
        while (this.dateDisabled(this.getFormattedDateString(initialDate), initialDate)) {
          initialDate.setDate(initialDate.getDate() + 1);
        }
        return this.getFormattedDateString(initialDate);
      }
    },
    setMinMaxDates() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      // today
      const minDate = new Date(today);
      // minDate.setDate(minDate.getDate() + 1)
      // one month ahead
      const maxDate = new Date(today)
      maxDate.setDate(maxDate.getDate() + 45)
      this.minDate = minDate;
      this.maxDate = maxDate;
    },
    formatZipcode(value) {
      if (
        (value.length > 5 && !hyphenZipCodeStartRegex.test(value)) ||
        !numbersOnlyRegex.test(value)
      ) {
        // extract all numbers
        let newValue = value.replace(/\D/g, '');
        if (newValue.length > 5) {
          // max 9 numbers
          if (newValue.length > 9) {
            newValue = `${newValue.slice(0, 9)}`;
          }
          // add hyphen
          newValue = [newValue.slice(0, 5), '-', newValue.slice(5)].join('');
        }
        this.$nextTick(() => {
          this.form.zipCode = newValue;
        });
      }
    },
    validZipcode(value) {
      return (value.length === 5 && numbersOnlyRegex.test(value)) || hyphenZipCodeRegex.test(value);
    },
  },
  mounted() {
    if (!this.$store.state.ocapi.customerGroup) {
      this.startAppLoading();
    }
    this.init()
      .then(() => {
        this.setInitialSenderProfile();
        this.setMinMaxDates();
      })
      .catch(error => {
        this.$store.dispatch('handleError', {
          title: 'Could not initialize',
          message: 'An error occurred while initializing products and categories',
          data: error
        })
      });
    if (this.$store.getters['front/getClientConfig']?.singleSendSSO) {
      window.onbeforeunload = function () {
        store.dispatch('ping/doLogout');
      }
    }
    window.history.replaceState(null, null, window.location.pathname);
  },
  watch: {
    senderProfileOptions: {
      handler: function (newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.setInitialSenderProfile();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control.is-invalid~.input-group-append .input-group-text {
  border-color: var(--danger);
}

.form-group {
  margin-bottom: 10px;

  .limiter {
    float: right;
  }

  textarea {
    margin-bottom: 0px;
  }
}

.form-control.is-valid~.input-group-append .input-group-text {
  border-color: var(--success);
}

::v-deep .modal-fullscreen {
  padding: 0 !important;
}

::v-deep .modal-fullscreen .modal-header {
  border-radius: 0 !important;
  border-color: transparent;
  background-color: transparent;
}

::v-deep .modal-fullscreen .modal-dialog {
  max-width: 100%;
  height: 100%;
  margin: 0;
}

::v-deep .modal-fullscreen .modal-body {
  background-color: transparent;
}

::v-deep .modal-fullscreen .modal-content {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  min-height: 100%;
  height: auto;
}

::v-deep .modal-fullscreen+.modal-backdrop {
  opacity: 0.8;
}

::v-deep .modal-preview .carousel-item {
  // height of all other vertical spacing elements in modal
  // h2 = 1.25rem * 1.5
  // header padding = 1rem * 2
  // header border width = 1px * 2
  // body padding = 1rem * 2
  // body bottom border width = 1px
  // modal vertical margin = 1.75rem * 2
  height: calc(90vh - (#{((1.25rem * 1.5) + 7.5rem)} + 3px));
  text-align: center;

  img {
    max-width: 100%;
    width: auto !important;
    //IE 11 fixes
  }
}

::v-deep .modal-preview .carousel-indicators {
  margin-bottom: 5px;
}

::v-deep .modal-preview .carousel-item .carousel-caption {
  color: #000;
  top: 10px;
  right: 10px;
  left: 10px;
  height: calc(90vh - (#{((1.25rem * 1.5) + 7.5rem)} + 3px));
  width: calc(60vw - (#{((1.25rem * 1.5) + 7.5rem)} + 3px));
}

::v-deep .carousel-control-next,
::v-deep .carousel-control-prev {
  width: 10%;
  z-index: 99;
}

::v-deep .carousel-control-next-icon,
::v-deep .carousel-control-prev-icon {
  background-color: #000;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background-size: 60%;
  background-position: center;
}

::v-deep .carousel-item.envelopeImage {
  text-align: center;

  img {
    object-fit: fill;
    max-width: 100%
  }

  .carousel-caption {
    margin: auto;
    width: 100%;
    max-width: 840px;
    text-shadow: none !important;
    vertical-align: middle;
    top: 10px;
    bottom: 10px;
    max-height: calc(75vw*.65);
    height: 100%;
    font-size: calc(10px + 5 * ((100vw - 320px) / 680));
  }
}

// @media (min-width: 576px) {
//   ::v-deep .modal-preview .modal-dialog {
//     max-width: 75%;
//   }
// }
.order-confirmation {
  h4 {
    margin-bottom: 0;
  }

  .row {
    margin-bottom: .5rem;
  }
}
</style>
