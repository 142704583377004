<template>
<footer class="login-footer bg-dark py-4">
      <b-container>
        <b-row align-v="center">
          <b-col cols="12" lg="3">
            <img src="/images/logos/hbc-logo-white-small.png" class="d-block img-fluid" alt="Hallmark Business Connections">
          </b-col>
          <b-col class="text-center text-muted" cols="12" lg="6">
            <p class="mb-0">&copy; Hallmark Business Connections. All rights reserved.</p>
            <p class="mb-0"><a href="mailto:Care.help@hallmarkbusinessconnections.com">Care.help@hallmarkbusinessconnections.com</a></p>
          </b-col>
          <b-col cols="12" lg="3">
            <div class="d-flex justify-content-center justify-content-lg-end w-100 text-muted">
              <a href="https://www.hallmarkbusiness.com/privacy-policy.html" target="_blank">Privacy &amp; Security</a>
              <a href="https://www.hallmarkbusiness.com/" target="_blank" class="ml-4">Company</a>
            </div>
          </b-col>
        </b-row>
      </b-container>
</footer>
</template>
<script>
export default {
  name: 'Footer'
}
</script>
<style lang="scss">  
  .login-footer {
    a {
      color: inherit;
    }
    img {
      height: 45px;
      width: auto;
    }
  }
  </style>