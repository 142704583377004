<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      authCheck: null,
      versionCheck: null,
    }
  },
  computed: {
    ...mapState('uptodate', [
      'reloadApp',
      'buildVersionCheck',
      'buildVersionCheckInterval',
    ]),
    ...mapState('ping', [
      'authenticated'
    ]),
    layout() {
      return this.$store.state.loading ? 'layout-loading' : `layout-${this.$route.meta.layout || 'front'}`;
    }
  },
  methods: {
    ...mapActions('uptodate', [
      'isUpToDate'
    ]),
    ...mapActions('ping', [
      'evaluateTokens'
    ])
  },
  mounted() {
    this.authCheck = setInterval(() => {
      if (this.authenticated) {
        this.evaluateTokens()
      }
    }, 1000 * 60);
    if (this.buildVersionCheck) {
      this.versionCheck = setInterval(() => {
        this.isUpToDate()
        .then(() => {
          if (this.reloadApp) {
            this.$router.go();
          }
        })
      }, this.buildVersionCheckInterval);
    }
  },
  beforeDestroy() {
    clearInterval(this.authCheck);
    if (this.buildVersionCheck) {
      clearInterval(this.versionCheck);
    }
  }
}
</script>
