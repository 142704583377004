<template>
  <div :id="`product-${card.product_id}`" :listIndex="index">
    <div class="product-image" @click="onClick" v-bind:style="{ backgroundImage: 'url(' + card.image.link + ')' }">
    </div>
    <h3 class="text-center h5 mt-3 productTitleClick" @click="onClick">{{ card.product_name }}</h3>
    <b-avatar
      v-if="isFavorite(card.product_id)"
      button
      icon="star-fill"
      variant="warning"
      @click="onClick"
      class="d-block mx-auto p-0"
    >
    </b-avatar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "SelectCard",
  props: {
    card: Object,
    index: Number
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('front', [
      'isFavorite'
    ])
  },
  methods: {
    onClick(e) {
      this.$gtm.trackEvent({
        event: 'view_item',
        category: (this.$store.state.front.activeCategory == "root") ? "view-all" : this.$store.state.front.activeCategory,
        action: 'click',
        label: this.card.product_name,
        value: 1,
        noninteraction: false,
      });
      this.$emit('click', e);
    }
  },
  
};
</script>
<style lang="scss" scoped>
.product-image {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor:pointer;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.productTitleClick {
  cursor: pointer;
}
</style>