<template>
<nav class="position-relative bg-light py-3 d-flex overflow-hidden">
  <ul class="nav-anchored pr-3 border-right mr-3">
    <li v-for="item in anchoredItems" :key="item.id">
      <b-button
        :class="{ 'active': item.id === active }"
        :aria-selected="item.id === active"
        :disabled="item.disabled"
        variant="link"
        @click="setActive(item.id)">
        {{ activeLanguage === 'es' && item.spanishName ? item.spanishName : item.name }}
      </b-button>
    </li>
  </ul>
  <div class="nav-scrollable-wrapper overflow-hidden">
    <div class="nav-scrollable" ref="nav">
      <ul ref="navContents">
        <li v-for="item in scrollableItems" :key="item.id">
          <b-button
            :class="{ 'active': item.id === active }"
            :aria-selected="item.id === active"
            :disabled="item.disabled"
            variant="link"
            @click="setActive(item.id)">
            {{ activeLanguage === 'es' && item.spanishName ? item.spanishName : item.name }}
          </b-button>
        </li>
      </ul>
    </div>
    <transition name="fade">
      <button
        v-if="showLeft"
        class="scroll-left"
        @click="advanceLeft">
        <b-icon icon="arrow-left"></b-icon>
      </button>
    </transition>
    <transition name="fade">
      <button
        v-if="showRight"
        class="scroll-right"
        @click="advanceRight">
        <b-icon icon="arrow-right"></b-icon>
      </button>
    </transition>
  </div>
</nav>
</template>
<script>
import { mapState } from 'vuex';
export default {
  // adapted from https://codepen.io/benfrain/pen/zZZLaP
  name: 'HorizontalScrollNavigation',
  props: [
    'items',
    'active',
  ],
  data() {
    return {
      travelling: false,
      travelDirection: '',
      travelDistance: 150,
      last_known_scroll_position: 0,
      ticking: false,
      overflow: 'none',
    };
  },
  methods: {
    setActive(id) {
      this.$emit('change', id);
    },
    determineOverflow() {
      const content = this.$refs.navContents;
      const container = this.$refs.nav;
      const containerMetrics = container.getBoundingClientRect();
      const containerMetricsRight = Math.floor(containerMetrics.right);
      const containerMetricsLeft = Math.floor(containerMetrics.left);
      const contentMetrics = content.getBoundingClientRect();
      const contentMetricsRight = Math.floor(contentMetrics.right);
      const contentMetricsLeft = Math.floor(contentMetrics.left);
      let overflow = '';
      if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
        overflow = 'both';
      } else if (contentMetricsLeft < containerMetricsLeft) {
        overflow = 'left';
      } else if (contentMetricsRight > containerMetricsRight) {
        overflow = 'right';
      } else {
        overflow = 'none';
      }
      this.overflow = overflow;
    },
    handleScroll() {
      this.last_known_scroll_position = window.scrollY;
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          this.determineOverflow();
          this.ticking = false;
        });
      }
      this.ticking = true;
    },
    handleTransitionEnd() {
      const nav = this.$refs.nav;
      const navContents = this.$refs.navContents;
      // get the value of the transform, apply that to the current scroll position (so get the scroll pos first) and then remove the transform
      const styleOfTransform = window.getComputedStyle(navContents, null);
      const tr = styleOfTransform.getPropertyValue('-webkit-transform') || styleOfTransform.getPropertyValue('transform');
      // If there is no transition we want to default to 0 and not null
      const amount = Math.abs(parseInt(tr.split(',')[4]) || 0);
      navContents.style.transform = 'none';
      navContents.classList.add('no-transition');
      // Now lets set the scroll position
      if (this.travelDirection === 'left') {
        nav.scrollLeft = nav.scrollLeft - amount;
      } else {
        nav.scrollLeft = nav.scrollLeft + amount;
      }
      this.travelling = false;
    },
    advanceLeft() {
      // If in the middle of a move return
      if (this.travelling === true) {
        return;
      }
      this.determineOverflow();
      // If we have content overflowing both sides or on the left
      if (this.overflow === 'left' || this.overflow === 'both') {
        const nav = this.$refs.nav;
        const navContents = this.$refs.navContents;
        // Find how far this panel has been scrolled
        const availableScrollLeft = nav.scrollLeft;
        // If the space available is less than two lots of our desired distance, just move the whole amount
        // otherwise, move by the amount in the settings
        if (availableScrollLeft < this.travelDistance * 2) {
            navContents.style.transform = `translateX(${availableScrollLeft}px)`;
        } else {
            navContents.style.transform = `translateX(${this.travelDistance}px)`;
        }
        // We do want a transition (this is set in CSS) when moving so remove the class that would prevent that
        navContents.classList.remove('no-transition');
        // Update our settings
        this.travelDirection = 'left';
        this.travelling = true;
      }
    },
    advanceRight() {
      // If in the middle of a move return
      if (this.travelling === true) {
          return;
      }
      this.determineOverflow();
      // If we have content overflowing both sides or on the right
      if (this.overflow === 'right' || this.overflow === 'both') {
          const nav = this.$refs.nav;
          const navContents = this.$refs.navContents;
          // Get the right edge of the container and content
          var navBarRightEdge = navContents.getBoundingClientRect().right;
          var navBarScrollerRightEdge = nav.getBoundingClientRect().right;
          // Now we know how much space we have available to scroll
          var availableScrollRight = Math.floor(navBarRightEdge - navBarScrollerRightEdge);
          // If the space available is less than two lots of our desired distance, just move the whole amount
          // otherwise, move by the amount in the settings
          if (availableScrollRight < this.travelDistance * 2) {
            navContents.style.transform = `translateX(-${availableScrollRight}px)`;
          } else {
            navContents.style.transform = `translateX(-${this.travelDistance}px)`;
          }
          // We do want a transition (this is set in CSS) when moving so remove the class that would prevent that
          navContents.classList.remove('no-transition');
          // Update our settings
          this.travelDirection = "right";
          this.travelling = true;
      }
    }
  },
  computed: {
    ...mapState('front', [
      'activeLanguage'
    ]),
    showLeft() {
      return this.overflow === 'left' || this.overflow === 'both';
    },
    showRight() {
      return this.overflow === 'right' || this.overflow === 'both';
    },
    anchoredItems() {
      return this.items.filter(item => item.id === 'favorites' || item.id === this.$store.state.front.defaultCategory);
    },
    scrollableItems() {
      return this.items.filter(item => item.id !== 'favorites' && item.id !== this.$store.state.front.defaultCategory);
    }
  },
  mounted() {
    const nav = this.$refs.nav;
    const navContents = this.$refs.navContents;
    navContents.addEventListener('transitionend', this.handleTransitionEnd, false);
    nav.addEventListener('scroll', this.handleScroll);
  },
  watch: {
    items() {
      this.$nextTick(() => {
        this.determineOverflow();
      })
    }
  }
}
</script>
<style lang="scss" scoped>
$light: #f8f9fa;
.scroll-left,
.scroll-right {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 1rem;
  height: 100%;
  border: none;
  font-size: 2rem;
  line-height: 1;
}
.scroll-left {
  left: 0;
  padding-left: 0;
  background: linear-gradient(to left, rgba($light, 0), $light 50%);
}
.scroll-right {
  right: 0;
  padding-right: 0;
  background: linear-gradient(to right, rgba($light, 0), $light 50%);
}
.no-transition {
  transition: none;
}
.nav-scrollable-wrapper {
  max-width: 100%;
  position: relative;
  overflow: hidden;
}
.nav-anchored {
  display:flex;
  align-items:center;
  button {
    white-space: nowrap;
  }
}
.nav-scrollable {
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  position: relative;
  font-size: 0;
  max-width: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
  li:last-child {
    margin-right: 30px;
  }
}
nav {
  button {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
}
ul {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: transform .2s ease-in-out;
	position: relative;
}
li {
  display: inline-flex;

}
.active {
  color:#000;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.btn.disabled {display: none;}
</style>